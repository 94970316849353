import React, { useEffect, useState } from "react";
import { API } from "../../../api";
import LinearTextArea from "../../../components/TextArea";
import feedback_star from "../../../assests/icons/feedback_star.svg";
import feedback_star_fill from "../../../assests/icons/feedback_star_fill.svg";
import NewModal from "../../../components/NewModal";
import clap from "../../../assests/icons/clap.svg";
import LinearButton from "../../../components/Button";
import { useHistory, useLocation } from "react-router-dom";
import Confetti from "react-confetti";

const CompleteChallenge = (props) => {
  const [userChallenge, setUserChallenge] = useState();
  const [stars, setStars] = useState([0, 0, 0, 0, 0]);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [showConfetti, setShowConfetti] = useState(false);

  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  const location = useLocation();

  const [showModal, setShowModal] = useState(false);

  const { challenge_id } = props.match.params;
  const user_challenge_id = location.state?.user_challenge_id;
  const goal_id = location.state?.goal_id;

  const history = useHistory();

  useEffect(() => {
    async function getUserChallenge() {
      const { data } = await API.get("user-data/challenge", user_challenge_id);
      setUserChallenge(data);
      console.log(userChallenge);
      // setStartTime(new Date(data.start_time));
      // setEndTime(new Date(data.end_time));
    }
    getUserChallenge();
  }, []);

  const _handleChange = (e) => {
    setFeedback(e.target.value);
  };

  const _handleSubmit = async () => {
    const { status } = await API.post(
      `challenge/respond/${userChallenge.challenge?.id}`,
      {
        id: userChallenge.id,
        user_feedback: feedback,
        user_response: rating,
        is_completed: true,
        goal_id,
      }
    );
    if (status === 200) {
      setShowModal(true);
      setShowConfetti(true);
    }
  };

  useEffect(() => {
    setStars([0, 0, 0, 0, 0]);
    const ones = Array(rating).fill(1);
    const zeros = Array(5 - rating).fill(0);

    setStars(ones.concat(zeros));
  }, [rating]);

  return (
    <div className=" flex flex-col items-center p-4">
      <h5 className="my-4">{userChallenge?.challenge?.name}</h5>
      <div
        className="text-xl px-8 md:px-32"
        style={{ color: "#2288EE", whiteSpace: "pre-wrap" }}
      >
        {userChallenge?.challenge?.description}
      </div>
      <div
        className="flex flex-col w-full md:w-3/4 lg:w-1/2 flex-wrap gap-8 my-4  p-4 py-4 text-center"
        style={{
          background: "#FFFFFF",
          boxShadow: "0px 2px 4px rgba(0, 51, 102, 0.1)",
          borderRadius: "10px",
        }}
      >
        <h5>
          Did you complete your{" "}
          {userChallenge?.challenge?.type === "drill" ? "drill" : "challenge"} ?
          Rate your experience
        </h5>
        <div className="flex flex-row gap-4 justify-center">
          {stars?.map((star, index) => {
            return (
              <div onClick={() => setRating(index + 1)} key={index}>
                {star === 0 ? (
                  <img alt="feedback_star" src={feedback_star} />
                ) : (
                  <img alt="feedback_star_fill" src={feedback_star_fill} />
                )}
              </div>
            );
          })}
        </div>
        <div className="body-small-1">
          Copy the filled contents from the document that you had downloaded
          earlier.
        </div>
        <LinearTextArea
          placeholder="Write your experience"
          columns={5}
          rows={5}
          onChange={_handleChange}
          value={feedback}
        />
        {/* <h5 className="text-center">Did you complete the challenge?</h5>
        <div className="flex flex-row justify-between gap-4 items-center">
          <div className="body-small-1">
            <p className="body-medium-1">FROM:</p>
            <br />
            {startTime?.getDate()} {MONTH_LIST[startTime?.getMonth()]},{" "}
            {startTime?.getFullYear()}
            <br />
            {WEEKDAY_LIST[startTime?.getDay()]}
            <br />
            {startTime?.getHours()} : {startTime?.getMinutes()}
          </div>
          <div className="body-small-1">
            <p className="body-medium-1">TO:</p>
            <br />
            {endTime?.getDate()} {MONTH_LIST[endTime?.getMonth()]},{" "}
            {endTime?.getFullYear()}
            <br />
            {WEEKDAY_LIST[endTime?.getDay()]}
            <br />
            {endTime?.getHours()} : {endTime?.getMinutes()}
          </div>
        </div> */}
      </div>
      <div style={{ right: "10px", bottom: "10px" }}>
        <button
          className="text-white py-4 px-8  bottom-10 right-10"
          style={{ background: "#003366", borderRadius: "10px" }}
          onClick={_handleSubmit}
        >
          Complete
        </button>
      </div>
      <div>
        {showModal && (
          <NewModal
            body={
              <div className="flex flex-col gap-8 items-center justify-center">
                <div>
                  <img alt="clap" src={clap} />
                </div>
                <div className="text-center">
                  <h5>Great Job!</h5>
                </div>
                <LinearButton
                  text="Return to Dashboard"
                  width="15rem"
                  onClick={() => {
                    history.push("/home", {
                      component: "Learn",
                    });
                  }}
                />
              </div>
            }
            hasCloseButton={false}
          />
        )}
      </div>
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          // recycle={showConfetti}
          numberOfPieces={200}
        />
      )}
    </div>
  );
};

export default CompleteChallenge;
