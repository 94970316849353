import React, { useEffect, useState } from "react";
import { API } from "../../api";
import "./index.css";
import ProgramContainer from "../../containers/Programs/ProgramContainer";
import { CircularProgress } from "@material-ui/core";
import ProgramCard from "../../containers/ProgramCard/ProgramCard";

const Sprint = ({ enrolledGoals }) => {
  const [sprintList, setSprintList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const enrolledGoalIds =
    enrolledGoals?.map((userProgram) => userProgram?.id) || [];

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const org_id = localStorage.getItem("org_id");
        let data, status;
        if (org_id !== "null" && org_id !== null) {
          ({ data, status } = await API.get("org-program-access", org_id));
        } else {
          ({ data, status } = await API.get("program", "all-by-user-role"));
        }
        if (status === 200) setSprintList(data.sprints);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className="p-4 my-4 bg-white rounded-lg flex justify-center items-center h-screen">
        <div style={{ marginTop: "-20vh" }}>
          <CircularProgress size={80} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="p-4 my-4 bg-white rounded-lg overflow-auto mx-2">
        <h4 className="text-center my-4 block sm:hidden">Select Sprint</h4>
        <div className="flex flex-row mt-4 flex-wrap justify-center gap-16 sm:gap-32">
          {/* {sprintList.map((sprint) => (
            <ProgramContainer
              key={sprint.id}
              isEnrolled={enrolledGoalIds.includes(sprint.id)}
              id={sprint.id}
              name={sprint.name}
              description={sprint.description}
              imageUrl={sprint.display_image_url}
              type={sprint.program_type}
            />
          ))} */}
          {sprintList?.map((sprint) => (
            <ProgramCard
              key={sprint.id}
              id={sprint.id}
              name={sprint.name}
              description={sprint.description}
              imageUrl={sprint.display_image_url}
              type={sprint.program_type}
              isEnrolled={enrolledGoalIds.includes(sprint.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sprint;
