import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import background_hill from "../../../../../assests/icons/background_hill.png";
import CancelIcon from "@material-ui/icons/Clear";
import useScreenSize from "../../../../../hooks/useScreenSize";

import { API } from "../../../../../api";

// Components
import FillBlanks from "../../FillBlanks";
import ProgressBar from "../../../../../components/Activity/ProgressBar";
import Order from "../Order";
import ReviewMCQ from "../MCQ";
import FlashCard from "../../../../ChallengeNew/FlashCard";
import Comment from "../../Comment";

const ReviewQuizContainer = ({ fetchURL = "" }) => {
  const [activity, setActivity] = useState();
  const [questionList, setQuestionList] = useState([]);
  const [sequence, setSequence] = useState(1);
  const [maxSequence, setMaxSequence] = useState(1);
  const [message] = useState("");
  const [response] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [isLoading, toggleLoading] = useState(true);
  const activityBodyEndRef = useRef();
  const activityBodyStartRef = useRef();
  const { isMobile } = useScreenSize();

  const history = useHistory();

  useEffect(() => {
    if (message)
      activityBodyEndRef.current?.scrollIntoView({ behavior: "smooth" });
    else {
      activityBodyStartRef.current?.scrollIntoView({ behaviour: "smooth" });
    }
  }, [message]);
  // useEffect(() => {
  //   if (!localStorage.getItem("token")) {
  //     history.push("/user/login");
  //   }
  // }, []);

  useEffect(() => {
    async function fetchData() {
      const { data } = await API.get(fetchURL);
      setQuestionList(data);
      setActivity(data);
      setMaxSequence(data.length);
      toggleLoading(false);
    }
    fetchData();
  }, []);

  const _nextQuestion = async (id) => {
    if (sequence < maxSequence) setSequence(sequence + 1);
  };

  const moveForward = (e) => {
    if (e.target.textContent.trim() === "Next") {
      _nextQuestion();
    } else if (e.target.textContent.trim() === "Finish") {
      setMaxSequence(maxSequence - 1);
      setDisableButton(true);
      e.target.textContent = "Finish";
      history.push("/home", {
        component: "Learn",
      });
    }
  };

  const handleSubmit = async (e) => {
    toggleLoading(true);
    if (e.target.textContent.trim() === "Check") {
      setDisableButton(true);
      if (questionList[sequence - 1]?.question?.type === "flashcard") {
        moveForward(e);
        return;
      }
      setDisableButton(false);
    } else {
      moveForward(e);
    }
    toggleLoading(false);
  };

  const displayQuestion = (type) => {
    if (type === "mcq")
      return (
        <ReviewMCQ
          options={questionList[sequence - 1].question?.components}
          id={questionList[sequence - 1].question_id}
          selectedButton={questionList[sequence - 1].response?.user_response}
        />
      );
    else if (type === "fill_blanks") {
      return (
        <FillBlanks
          // sendResponse={getResponse}
          id={questionList[sequence - 1].question_id}
          selected={questionList[sequence - 1].response?.user_response}
        />
      );
    } else if (type === "order") {
      return (
        <Order
          id={questionList[sequence - 1].question.id}
          options={questionList[sequence - 1].question?.components}
          // sendResponse={getResponse}
        />
      );
    } else if (type === "flashcard") {
      return (
        <>
          <FlashCard
            image={questionList[sequence - 1].question?.question_image_url}
            id={questionList[sequence - 1].question?.id}
          />
        </>
      );
    }
  };

  if (!activity || !questionList) return null;
  // if (!goal_id) return null;

  return (
    <div className="w-11/12 sm:w-2/3 flex flex-col gap-4 mx-auto mt-16">
      <div className="flex flex-row justify-center items-center">
        <ProgressBar
          bgcolor={"#ef6c00"}
          completed={parseInt(((sequence - 1) / maxSequence) * 100)}
        />
        <CancelIcon
          className="cursor-pointer"
          onClick={() => {
            history.push("/home", {
              component: "Learn",
            });
            // setShowQuit(true);
          }}
        />
      </div>
      <div
        className="scroll"
        style={{
          zIndex: 100,
          overflowY: "scroll",
          scrollBehavior: "smooth",
          maxHeight: "65vh",
        }}
      >
        <div ref={activityBodyStartRef}></div>
        <div style={{ whiteSpace: "pre-wrap" }}>
          {isMobile ||
          questionList[sequence - 1]?.question?.stem?.split(" ").length > 20 ? (
            <h6 className="mb-4 text-justify">
              {questionList[sequence - 1]?.question?.stem}
            </h6>
          ) : (
            <h5 className="text-center ">
              {questionList[sequence - 1]?.question?.stem}
            </h5>
          )}

          <div style={{ marginBottom: "15px" }}>
            {displayQuestion(questionList[sequence - 1]?.question?.type)}
          </div>
        </div>
        {questionList[sequence - 1]?.question?.type === "flashcard" && (
          <div> {questionList[sequence - 1]?.question?.comment}</div>
        )}

        <div className="relative">
          {questionList[sequence - 1]?.question?.type != "flashcard" && (
            <Comment
              message={
                questionList[sequence - 1].response?.is_correct
                  ? "CORRECT ANSWER"
                  : "INCORRECT ANSWER"
              }
              comment={questionList[sequence - 1]?.question?.comment}
              bgcolor={
                questionList[sequence - 1].response?.is_correct
                  ? "#09B746"
                  : "#C82020"
              }
              snippetShow={true}
            />
          )}
        </div>

        {/* 
        <div
          className="body-medium-2 text-center z-10"
          style={{
            display:
              questionList[sequence - 1]?.question?.type != "flashcard"
                ? "block"
                : "none", //snippetShow ? "block" : "none",
            background: "#FDF9F0",
            overflow: "auto",
            height: "auto",
          }}
        >
          <div
            className="quarter-circle-top-right z-10"
            style={{
              backgroundColor: questionList[sequence - 1].response?.is_correct
                ? "#09B746"
                : "#C82020",
            }}
          ></div>
          <div className="subtitle text-center my-4">
            {questionList[sequence - 1].response?.is_correct
              ? "CORRECT ANSWER"
              : "INCORRECT ANSWER"}
          </div>
          <div className="text-center px-4">
            {questionList[sequence - 1]?.question?.comment}
          </div>
          <div
            className="quarter-circle-bottom-left"
            style={{
              backgroundColor: questionList[sequence - 1].response?.is_correct
                ? "#09B746"
                : "#C82020",
            }}
          ></div>
        </div>
        */}
        <div className="mb-16 sm:mt-0" ref={activityBodyEndRef}></div>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          left: 0,
          display: "flex",
          flexDirection: "column",
          zIndex: 1,
        }}
      >
        <img
          src={background_hill}
          style={{ height: "150px", objectFit: "fit" }}
          alt="background_hill"
        />
        <div className="p-4 flex flex-row justify-between mx-2 md:mx-32">
          {sequence > 1 ? (
            <button
              onClick={() => setSequence((sequence) => sequence - 1)}
              disabled={disableButton}
              className="text-white px-8 py-4 w-36 rounded-2xl text-xl shadow-lg"
              style={{
                backgroundColor: "#003366",
              }}
            >
              Back
            </button>
          ) : (
            <div></div>
          )}
          <button
            onClick={handleSubmit}
            disabled={disableButton}
            className="text-white px-8 py-4 w-36 rounded-2xl text-xl shadow-lg"
            style={{
              backgroundColor: "#003366",
            }}
          >
            {true || questionList[sequence - 1]?.question?.type === "flashcard"
              ? sequence === activity.length
                ? "Finish"
                : "Next"
              : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewQuizContainer;
