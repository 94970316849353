import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ProgramModal from "../Programs/ProgramModal";
import { ReactComponent as DefaultProgramImage } from "../../../src/assests/images/default_program_productivity_image.svg";

const ProgramCard = ({ isEnrolled, id, name, description, imageUrl, type }) => {
  const [isModalVisible, setModalVisibility] = useState(false);

  const onEnrollClick = () => {
    setModalVisibility(true);
  };

  const onCloseModal = () => {
    setModalVisibility(false);
  };

  return (
    <>
      {isModalVisible && (
        <ProgramModal
          isEnrolled={isEnrolled}
          name={name}
          description={description}
          image={
            imageUrl ? (
              <img src={imageUrl} alt={name} />
            ) : (
              <DefaultProgramImage />
            )
          }
          id={id}
          onClose={onCloseModal}
          type={type}
        />
      )}

      <Card
        sx={{
          width: 370,
          minHeight: 270,
          display: "flex",
          alignItems: "center",
          padding: 2,
          borderRadius: "1rem",
          boxShadow: 3,
        }}
      >
        <Grid container spacing={2} alignItems="center">
          {/* Image Section */}
          <Grid item xs={4}>
            <Box
              sx={{
                width: 100,
                height: 100,
                borderRadius: "50%",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: 2,
                backgroundColor: "#f5f5f5",
              }}
            >
              <CardMedia
                component="img"
                image={imageUrl || "/static/images/default_program.jpg"}
                alt={name}
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Grid>

          {/* Content Section */}
          <Grid item xs={8}>
            <CardContent sx={{ padding: 0 }}>
              <Typography variant="h6" component="div">
                {name}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mt: 0.5 }}
              >
                {description.length > 90
                  ? description.slice(0, 90) + "..."
                  : description}
              </Typography>
            </CardContent>

            {/* Action Button */}
            <CardActions sx={{ padding: 0, mt: 1 }}>
              <Button
                fullWidth
                size="small"
                variant="contained"
                color={isEnrolled ? "success" : "primary"}
                onClick={onEnrollClick}
                sx={{ borderRadius: "0.5rem" }}
              >
                {isEnrolled ? "Enrolled" : "Enroll"}
              </Button>
            </CardActions>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ProgramCard;
