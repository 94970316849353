import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Alert, CircularProgress, Tooltip } from "@mui/material";
import AddUser from "@mui/icons-material/PersonAddAlt";
import AddMultipleUser from "@mui/icons-material/GroupAdd";

import { API } from "../../../api";
import NewModal from "../../../components/NewModal";
import LinearButton from "../../../components/Button";
import ViewMembers from "@mui/icons-material/Visibility";
import SendIcon from "@mui/icons-material/Send";
import { validateEmail } from "../../../lib/helpers";

import BlankCSV from "../../../assests/Files/Blank_CSV.csv";
import Papa from "papaparse";
import { useHistory } from "react-router-dom";

const User = () => {
  const history = useHistory();

  const allowedExtensions = ["csv"];

  const [members, setMembers] = useState([]);

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const [userEmail, setUserEmail] = useState("");

  const [file, setFile] = useState("");

  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const [bulkEmail, setBulkEmail] = useState({
    user_email_1: "",
    user_email_2: "",
    user_email_3: "",
    user_email_4: "",
    user_email_5: "",
    user_email_6: "",
  });

  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showAddBulkUserModal, setShowAddBulkUserModal] = useState(false);
  const [showCsvUploadModal, setShowCsvUploadModal] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isButtonProgress, setIsButtonProgress] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);

  const handleChange = (e) => {
    setUserEmail(e.target.value.toLowerCase());
  };

  const resetBulkEmail = () => {
    setBulkEmail({
      user_email_1: "",
      user_email_2: "",
      user_email_3: "",
      user_email_4: "",
      user_email_5: "",
      user_email_6: "",
    });
  };

  const _handleBulkEmailChange = (e, value) => {
    setBulkEmail({ ...bulkEmail, [e.target.name]: e.target.value });
  };

  const addSingleUser = async () => {
    const activateTimer = () => {
      setTimeout(() => {
        setMessage("");
      }, 6000);
    };

    setIsButtonProgress(true);
    if (!validateEmail(userEmail)) {
      setSeverity("error");
      setMessage("Please Enter Valid Email.");
      setIsButtonProgress(false);
      activateTimer();
      return;
    }

    try {
      const { data, status } = await API.post("user/org-add", {
        user_email_list: [userEmail],
      });

      if (status === 200) {
        setSeverity("success");
        setMessage("User added successfully");
        getOrgMembers();
        activateTimer();
      } else {
        setSeverity("error");
        setMessage(data.error ? data.error.message : "Error Occurred");
        activateTimer();
      }
    } catch (error) {
      setSeverity("error");
      setMessage("An error occurred. Please try again later.");
      activateTimer();
    }
    setUserEmail("");
    setIsButtonProgress(false);
  };

  const addBulkUser = async () => {
    const activateTimer = () => {
      setTimeout(() => {
        setMessage("");
      }, 6000);
    };

    setIsButtonProgress(true);
    const emailArray = Object.values(bulkEmail).filter(
      (email) => email.trim() !== ""
    );
    if (emailArray.length < 2) {
      setSeverity("error");
      setMessage("Enter At-least 2 fields.");
      setIsButtonProgress(false);
      activateTimer();
      return;
    }
    const uniqueEmailSet = new Set(emailArray);

    if (uniqueEmailSet.size !== emailArray.length) {
      setSeverity("error");
      setMessage("All emails should be unique.");
      setIsButtonProgress(false);
      activateTimer();
      return;
    }

    try {
      const { data, status } = await API.post("user/org-add", {
        user_email_list: emailArray,
      });

      if (status === 200) {
        setSeverity("success");
        setMessage("Users added successfully");
        getOrgMembers();
        activateTimer();
      } else {
        setSeverity("error");
        setMessage(data.error ? data.error.message : "Error Occurred");
        activateTimer();
      }
    } catch (error) {
      setSeverity("error");
      setMessage("An error occurred. Please try again later.");
      activateTimer();
    }
    resetBulkEmail();
    setIsButtonProgress(false);
  };

  const handleAddCsvData = async () => {
    setMessage("");
    setIsButtonProgress(true);

    if (!file) {
      setSeverity("error");
      setMessage("Enter a valid file");
      setIsButtonProgress(false);

      return;
    }

    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      try {
        const requiredHeaders = ["first_name", "last_name", "email"];
        const csv = Papa.parse(target.result, { header: true });
        const parsedData = csv?.data;
        const columns = parsedData.length > 0 ? Object.keys(parsedData[0]) : [];
        for (const requiredHeader of requiredHeaders) {
          if (!columns.includes(requiredHeader)) {
            setSeverity("error");
            setMessage('Required header "' + requiredHeader + '" is missing.');
            setFile("");
            setIsButtonProgress(false);

            return;
          }
        }
        for (const row of parsedData) {
          if (row.email === "") {
            setSeverity("error");
            setMessage("Email is missing in a row.");
            setFile("");
            setIsButtonProgress(false);
            return;
          }
        }
        try {
          const { data, status } = await API.post("user/csv-add", {
            user_list: parsedData,
          });

          if (status === 200) {
            setSeverity("success");
            setMessage("Users added successfully");
            getOrgMembers();
          } else {
            setSeverity("error");
            setMessage(data.error ? data.error.message : "Error Occurred");
          }
        } catch (error) {
          setSeverity("error");
          setMessage("An error occurred. Please try again later.");
        }
      } catch (error) {
        setSeverity("error");
        setMessage("Error parsing CSV file.");
      }
      setFile("");
      setIsButtonProgress(false);
    };

    reader.readAsText(file);
  };

  const handleCSVFileChange = (e) => {
    setMessage("");
    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setSeverity("error");
        setMessage("Please input a csv file");
        setFile("");
        return;
      }
      setFile(inputFile);
    }
  };

  const blankExcelDownload = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = BlankCSV;
    downloadLink.download = "BlankCsv.csv";
    downloadLink.click();
  };

  const getOrgMembers = async () => {
    setIsLoading(true);
    try {
      const { data } = await API.get("user", "org-members");
      setMembers(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getOrgMembers();
  }, []);

  const handleViewButtonClick = (event, params) => {
    const rowData = params.row;
    history.push("/administrator/user-details", {
      component: "user-program",
      rowData,
    });
  };

  useEffect(() => {
    const headerData = ["Name", "Email", "Registered", "Actions"];
    const updatedColumns = headerData.map((headerText, index) => ({
      field: headerText.toLowerCase(),
      headerName: headerText,
      flex: 1,
      headerClassName: () => "bg-gray-100 border-b-2 border-gray-200",
      renderCell: (params) => {
        if (headerText === "Actions") {
          return (
            <button
              onClick={(event) => {
                handleViewButtonClick(event, params);
              }}
              className="flex space-x-1 bg-transparent items-center p-2 cursor-pointer"
            >
              <Tooltip title="View User Details" arrow placement="top">
                <svg
                  className="w-6 h-6 -mt-2"
                  style={{ color: "#2288EE", fill: "#2288EE" }}
                  viewBox="0 0 20 20"
                >
                  <ViewMembers />
                </svg>
              </Tooltip>
            </button>
          );
        }
      },
    }));
    const updatedRows = members?.map((member, index) => ({
      id: index,
      name: member?.first_name
        ? member?.first_name + " " + member?.last_name
        : member?.email.split("@")[0],
      email: member?.email,
      registered: member?.registered,
    }));
    setRows(updatedRows);
    setColumns(updatedColumns);
  }, [members]);

  useEffect(() => {
    const selectedEmails = rowSelectionModel
      .map((selectedId) => rows.find((item) => item.id === selectedId))
      .map(({ email }) => email);
    setSelectedRows(selectedEmails);
  }, [rowSelectionModel]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div>
      <div className="p-4 my-4 bg-white rounded-lg overflow-auto mx-2">
        <header>
          <div className="flex justify-center items-center">
            <div className="flex text-center my-4 text-gray-500 mt-0">
              <button
                onClick={() => setShowAddUserModal(true)}
                className="flex space-x-1 bg-transparent items-center p-2 cursor-pointer bg-white text-blue-500 hover:bg-blue-50 transition-all duration-200 rounded"
              >
                <svg
                  className="w-6 h-6"
                  style={{ color: "#2288EE", fill: "#2288EE" }}
                  viewBox="0 0 20 20"
                >
                  <AddUser />
                </svg>
                <p className="text-gray-800">Add a user</p>
              </button>

              <button
                onClick={() => setShowAddBulkUserModal(true)}
                className="flex space-x-1 bg-transparent items-center p-2 cursor-pointer bg-white text-blue-500 hover:bg-blue-50 transition-all duration-200 rounded"
              >
                <svg
                  className="w-6 h-6"
                  style={{ color: "#2288EE", fill: "#2288EE" }}
                  viewBox="0 0 20 20"
                >
                  <AddMultipleUser />
                </svg>
                <p className="text-gray-800">Add multiple users</p>
              </button>
            </div>
          </div>
        </header>
        <main>
          <div>
            {isLoading ? (
              <div className="flex justify-center items-center h-screen">
                <CircularProgress size={80} />
              </div>
            ) : members.length ? (
              <DataGrid
                rows={rows}
                columns={columns}
                keyField="id"
                checkboxSelection
                sx={{
                  "& .MuiDataGrid-columnHeaderCheckbox": {
                    backgroundColor: "#F3F4F6",
                    borderBottom: "2px solid #e5e7eb",
                  },
                }}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                slots={{
                  toolbar: () => <CustomToolbar />,
                }}
              />
            ) : (
              <div class="flex justify-center items-start min-h-screen bg-white mt-8">
                <div class="text-center p-8 bg-white-200 rounded-t-lg shadow-md w-full md:w-1/2">
                  <p class="text-3xl font-bold text-gray-500 mb-4">
                    No Users in this Organization
                  </p>
                  <p class="text-gray-700">
                    It looks like there are no users in this organization at the
                    moment.
                  </p>
                  <p class="text-gray-700">Onboard users to start!</p>
                </div>
              </div>
            )}
          </div>
        </main>
        {showAddUserModal && (
          <NewModal
            heading={<div className="block text-center">New User Email</div>}
            body={
              <div className="mx-16">
                <div className="w-full">
                  <label className="block mb-1 text-sm font-medium text-gray-600">
                    User Email
                  </label>
                  <input
                    className="w-full px-3 py-2 rounded-md border border-gray-300 outline-none focus:ring focus:ring-blue-400 transition-all duration-300"
                    type="text"
                    placeholder="abc@linear.live"
                    value={userEmail}
                    onChange={handleChange}
                  />
                </div>

                <div className="w-full mt-4">
                  <LinearButton
                    onClick={addSingleUser}
                    text={
                      isButtonProgress ? (
                        <CircularProgress size={30} />
                      ) : (
                        "Submit"
                      )
                    }
                  />
                </div>

                <div className="mt-6 flex justify-center inline-block ">
                  {message && message !== "" && (
                    <Alert severity={severity}>{message}</Alert>
                  )}
                </div>
              </div>
            }
            onClose={() => {
              setShowAddUserModal(false);
            }}
          />
        )}
        {showAddBulkUserModal && (
          <NewModal
            heading={<div className="block text-center">User Emails</div>}
            body={
              <div className="mx-16">
                <div className="flex">
                  <div className="flex space-x-4">
                    <div className="w-full">
                      <label className="block mb-1 text-sm font-medium text-gray-600">
                        1. Email
                      </label>
                      <input
                        className="w-full px-3 py-2 rounded-md border border-gray-300 outline-none focus:ring focus:ring-blue-400 transition-all duration-300"
                        type="text"
                        placeholder="abc@linear.live"
                        name="user_email_1"
                        value={bulkEmail.user_email_1}
                        onChange={_handleBulkEmailChange}
                      />
                    </div>
                    <div className="w-full">
                      <label className="block mb-1 text-sm font-medium text-gray-600">
                        2. Email
                      </label>
                      <input
                        className="w-full px-3 py-2 rounded-md border border-gray-300 outline-none focus:ring focus:ring-blue-400 transition-all duration-300"
                        type="text"
                        placeholder="abc@linear.live"
                        name="user_email_2"
                        value={bulkEmail.user_email_2}
                        onChange={_handleBulkEmailChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex mt-4">
                  <div className="flex space-x-4">
                    <div className="w-full">
                      <label className="block mb-1 text-sm font-medium text-gray-600">
                        3. Email
                      </label>
                      <input
                        className="w-full px-3 py-2 rounded-md border border-gray-300 outline-none focus:ring focus:ring-blue-400 transition-all duration-300"
                        type="text"
                        placeholder="abc@linear.live"
                        name="user_email_3"
                        value={bulkEmail.user_email_3}
                        onChange={_handleBulkEmailChange}
                      />
                    </div>
                    <div className="w-full">
                      <label className="block mb-1 text-sm font-medium text-gray-600">
                        4. Email
                      </label>
                      <input
                        className="w-full px-3 py-2 rounded-md border border-gray-300 outline-none focus:ring focus:ring-blue-400 transition-all duration-300"
                        type="text"
                        placeholder="abc@linear.live"
                        name="user_email_4"
                        value={bulkEmail.user_email_4}
                        onChange={_handleBulkEmailChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex mt-4">
                  <div className="flex space-x-4">
                    <div className="w-full">
                      <label className="block mb-1 text-sm font-medium text-gray-600">
                        5. Email
                      </label>
                      <input
                        className="w-full px-3 py-2 rounded-md border border-gray-300 outline-none focus:ring focus:ring-blue-400 transition-all duration-300"
                        type="text"
                        placeholder="abc@linear.live"
                        name="user_email_5"
                        value={bulkEmail.user_email_5}
                        onChange={_handleBulkEmailChange}
                      />
                    </div>
                    <div className="w-full">
                      <label className="block mb-1 text-sm font-medium text-gray-600">
                        6. Email
                      </label>
                      <input
                        className="w-full px-3 py-2 rounded-md border border-gray-300 outline-none focus:ring focus:ring-blue-400 transition-all duration-300"
                        type="text"
                        placeholder="abc@linear.live"
                        name="user_email_6"
                        value={bulkEmail.user_email_6}
                        onChange={_handleBulkEmailChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full mt-4">
                  <LinearButton
                    onClick={addBulkUser}
                    text={
                      isButtonProgress ? (
                        <CircularProgress size={30} />
                      ) : (
                        "Submit"
                      )
                    }
                  />
                </div>

                <div className="mt-6 flex justify-center inline-block ">
                  {message && message !== "" && (
                    <Alert severity={severity}>{message}</Alert>
                  )}
                </div>

                <div className="flex space-x-2 justify-center">
                  <p
                    onClick={() => {
                      setShowCsvUploadModal(true);
                      setShowAddBulkUserModal(false);
                    }}
                    className="block mt-2 text-sm font-medium text-blue-600 cursor-pointer"
                  >
                    Upload csv
                  </p>
                  <p className="block mt-2 text-sm font-medium text-blue-600">
                    or
                  </p>
                  <p
                    onClick={blankExcelDownload}
                    className="block mt-2 text-sm font-medium text-blue-600 cursor-pointer"
                  >
                    Download a blank csv
                  </p>
                </div>
              </div>
            }
            onClose={() => {
              setShowAddBulkUserModal(false);
            }}
          />
        )}
        {showCsvUploadModal && (
          <NewModal
            heading={<div className="block text-center">UPLOAD CSV </div>}
            body={
              <div className="mx-16">
                <div className="block my-4 text-gray-500 mt-0">
                  <input
                    onChange={handleCSVFileChange}
                    name="file"
                    type="file"
                  />
                </div>

                <div className="mt-4">
                  <LinearButton
                    onClick={handleAddCsvData}
                    text={
                      isButtonProgress ? (
                        <CircularProgress size={30} />
                      ) : (
                        "Submit"
                      )
                    }
                  />
                </div>

                <div className="mt-6 flex justify-center">
                  {message && message !== "" && (
                    <Alert severity={severity}>{message}</Alert>
                  )}
                </div>
              </div>
            }
            onClose={() => {
              setMessage("");
              setShowCsvUploadModal(false);
              setFile("");
            }}
          />
        )}
      </div>
    </div>
  );
};

export default User;
