import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  TextField,
  Collapse,
  Avatar,
  Divider,
} from "@mui/material";
import { Link, useLocation, useHistory } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BarChartIcon from "@mui/icons-material/BarChart";
import PersonIcon from "@mui/icons-material/Person";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";
import MyProgram from "@mui/icons-material/LibraryBooks";
import LearIcon from "@mui/icons-material/LocalLibrary";
import JournalIcon from "@mui/icons-material/Book";
import LeaderBoardIcon from "@mui/icons-material/Groups";
import TrophyIcon from "@mui/icons-material/EmojiEvents";
import LogoutIcon from "@mui/icons-material/PowerSettingsNew";
import ProgramIcon from "@mui/icons-material/School";
import SprintIcon from "@mui/icons-material/DirectionsRun";
import LearnNextIcon from "@mui/icons-material/Lightbulb";
import AdminLockIcon from "@mui/icons-material/Lock";
import AdminLockOpenIcon from "@mui/icons-material/LockOpen";
import ReportsIcon from "@mui/icons-material/Summarize";
import OrganizationIcon from "@mui/icons-material/CorporateFare";
import GroupsIcon from "@mui/icons-material/Groups3";
import SupportIcon from "@mui/icons-material/SupportAgent";
import ManageUsers from "@mui/icons-material/PersonAdd";
import SearchUser from "@mui/icons-material/Search";
import PersonalDetail from "@mui/icons-material/ManageAccounts";
import UserDashboard from "../User/UserDashboard";
import TeamLeaderboard from "../Team/Leaderboard";
import MyPrograms from "../Program/MyPrograms";
import Reports from "../Admin/Reports";
import Achievements from "../UserProfile/Achievment";
import SavedTips from "../UserJournal/Tips";
import FlashCardsJournalComponent from "../UserJournal/FlashCard";
import UserChallenge from "../UserJournal/Challenge";
import Notes from "../UserJournal/Notes";
import Settings from "../UserProfile/Settings";
import GraphCharts from "../GraphCharts/GraphCharts";
import Program from "../Program/Program";
import Sprint from "../Program/Sprint";
import UserProfile from "../UserProfile/PersonaDetails";
import OrgDetails from "../Administrator/OrgDetails/OrgDetails";
import Group from "../Administrator/Group/Group";
import User from "../Administrator/User";
import UserSearch from "../Administrator/UserSearch/UserSearch";
import Support from "../Support";
import AppBarComponent from "./Components/AppBarComponent";
import Tip from "../../components/DashboardComponents/Tip";
import { Alert } from "@mui/material";
import Dialog from "@mui/material/Dialog";

const drawerWidth = 240;
const miniDrawerWidth = 65;
const headerHeight = 64;

const AnalyticDash = (props) => {
  const userData = useStoreState((state) => state.user.data);
  const { role_id, org_id } = userData;
  const history = useHistory();
  const location = useLocation();
  const deleteUser = useStoreActions((a) => a.user.delete);
  let propsComponent = props.location?.state?.component;

  const [showDashboard, setShowDashboard] = useState(true);
  const [showTeam, setShowTeam] = useState(true);
  const [showLearnNext, setShowLearnNext] = useState(true);
  const [showProfileOpen, setShowProfileOpen] = useState(true);

  const [selectedItem, setSelectedItem] = useState("");
  const [journalComponent, setJournalComponent] = useState("tips");

  const [dashboardOpen, setDashboardOpen] = useState(false);
  const [learnOpen, setLearnOpen] = useState(false);
  const [teamOpen, setTeamOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);

  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const [open, setOpen] = useState(true);

  //Receiving message from broadcast channel
  const broadcastChannel = new BroadcastChannel("new-content-channel");
  broadcastChannel.onmessage = (event) => {
    if (event.data && event.data.type === "newContentAvailable") {
      setOpenUpdateModal(true);
    }
  };

  const dashboardSubItems = [
    { text: "Learn", icon: <LearIcon /> },
    { text: "My Learning", icon: <MyProgram /> },
    { text: "LeaderBoard", icon: <LeaderBoardIcon /> },
  ];

  const learNextItems = [
    { text: "Programs", icon: <ProgramIcon /> },
    { text: "Sprints", icon: <SprintIcon /> },
  ];

  const menuBottomItems = [
    {
      text: "Support",
      icon: <SupportIcon />,
    },
    {
      text: "Logout",
      icon: (
        <LogoutIcon
          onClick={(e) => {
            localStorage.clear();
            sessionStorage.clear();
            deleteUser();
            history.push("/user/login");
          }}
        />
      ),
    },
  ];

  const profileSubItems = [
    { text: "Personal Details", icon: <PersonalDetail /> },
    { text: "Achievements", icon: <TrophyIcon /> },
    { text: "Journal", icon: <JournalIcon /> },
    { text: "Settings", icon: <SettingsIcon /> },
  ];

  const teamSubItems = [];
  if ([3, 4, 5, 6].includes(role_id)) {
    teamSubItems.push(
      { text: "Analytics", icon: <BarChartIcon /> },
      { text: "Reports", icon: <ReportsIcon /> },
      { text: "Organization", icon: <OrganizationIcon /> },
      { text: "Groups", icon: <GroupsIcon /> },
      { text: "Manage Users", icon: <ManageUsers /> },
      { text: "Search", icon: <SearchUser /> }
    );
  }
  if ([2].includes(role_id)) {
    teamSubItems.push(
      { text: "Analytics", icon: <BarChartIcon /> },
      { text: "Reports", icon: <ReportsIcon /> },
      { text: "Groups", icon: <GroupsIcon /> },
      { text: "Search", icon: <SearchUser /> }
    );
  }

  useEffect(() => {
    if (propsComponent) {
      setSelectedItem(propsComponent);

      // Reset all states
      setDashboardOpen(false);
      setLearnOpen(false);
      setTeamOpen(false);
      setProfileOpen(false);

      // Determine which section the component belongs to
      if (dashboardSubItems.some((item) => item.text === propsComponent)) {
        setDashboardOpen(true);
      } else if (learNextItems.some((item) => item.text === propsComponent)) {
        setLearnOpen(true);
      } else if (profileSubItems.some((item) => item.text === propsComponent)) {
        setProfileOpen(true);
      } else if (teamSubItems.some((item) => item.text === propsComponent)) {
        setTeamOpen(true);
      }

      // Remove the component from the URL state
      const newState = { ...props.location.state, component: null };
      window.history.replaceState(newState, "");
    } else {
      // Default behavior if no component is provided
      if ([2, 3, 4, 5, 6].includes(role_id)) {
        setSelectedItem("Analytics");
        setDashboardOpen(false);
        setTeamOpen(true);
        setShowTeam(true);
      } else {
        setSelectedItem("Learn");
        setDashboardOpen(true);
        setTeamOpen(false);
        setShowTeam(false);
      }
    }
  }, [propsComponent, role_id]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleProfileToggle = () => {
    setProfileOpen(!profileOpen);
  };

  const handleDashboardToggle = () => {
    setDashboardOpen(!dashboardOpen);
  };

  const handleLearnNextToggle = () => {
    setLearnOpen(!learnOpen);
  };

  const handleTeamNextToggle = () => {
    setTeamOpen(!teamOpen);
  };

  const displayJournalComponent = () => {
    if (journalComponent === "tips") return <SavedTips />;
    else if (journalComponent === "todo") return <FlashCardsJournalComponent />;
    else if (journalComponent === "challenge") return <UserChallenge />;
    else if (journalComponent === "note") return <Notes />;
  };

  const displayComponent = () => {
    if (selectedItem === "Learn") {
      return <UserDashboard />;
    } else if (selectedItem === "My Learning") {
      return <MyPrograms />;
    } else if (selectedItem === "LeaderBoard") {
      return <TeamLeaderboard />;
    } else if (selectedItem === "Programs") {
      return <Program />;
    } else if (selectedItem === "Sprints") {
      return <Sprint />;
    } else if (selectedItem === "Personal Details") {
      return <UserProfile />;
    } else if (selectedItem === "Achievements") {
      return <Achievements />;
    } else if (selectedItem === "Journal") {
      return (
        <div className="bg-white rounded-lg">
          <div className="flex flex-row gap-16 bg-white p-4 mx-4 my-4">
            <div
              className="cursor-pointer"
              onClick={() => setJournalComponent("tips")}
              style={
                journalComponent === "tips"
                  ? {
                      color: "var(--blue-de-france)",
                      borderBottom: "1px solid var(--blue-de-france)",
                    }
                  : null
              }
            >
              Tips
            </div>
            <div
              className="cursor-pointer"
              onClick={() => setJournalComponent("todo")}
              style={
                journalComponent === "todo"
                  ? {
                      color: "var(--blue-de-france)",
                      borderBottom: "1px solid var(--blue-de-france)",
                    }
                  : null
              }
            >
              Flashcards
            </div>
            <div
              className="cursor-pointer"
              onClick={() => setJournalComponent("challenge")}
              style={
                journalComponent === "challenge"
                  ? {
                      color: "var(--blue-de-france)",
                      borderBottom: "1px solid var(--blue-de-france)",
                    }
                  : null
              }
            >
              Challenge
            </div>
            <div
              className="cursor-pointer"
              onClick={() => setJournalComponent("note")}
              style={
                journalComponent === "note"
                  ? {
                      color: "var(--blue-de-france)",
                      borderBottom: "1px solid var(--blue-de-france)",
                    }
                  : null
              }
            >
              My notes
            </div>
          </div>
          {displayJournalComponent()}
        </div>
      );
    } else if (selectedItem === "Analytics") {
      return <GraphCharts />;
    } else if (selectedItem === "Reports") {
      return <Reports role_id={role_id} org_id={org_id} />;
    } else if (selectedItem === "Organization") {
      return <OrgDetails />;
    } else if (selectedItem === "Groups") {
      return <Group />;
    } else if (selectedItem === "Manage Users") {
      return <User />;
    } else if (selectedItem === "Search") {
      return <UserSearch />;
    } else if (selectedItem === "Settings") {
      return <Settings />;
    } else if (selectedItem === "Support") {
      return <Support />;
    }
  };

  const drawer = (
    <div>
      <List
        sx={{
          paddingTop: "0px",
        }}
      >
        {/* Collapsible Dashboard Items*/}
        <ListItem disablePadding className="block">
          <ListItemButton
            onClick={handleDashboardToggle}
            className={`min-h-[48px] justify-center ${
              open ? "px-2.5" : "px-2.5 py-3"
            }`}
          >
            <ListItemIcon className={`min-w-0 ${open ? "mr-3" : "mr-auto"}`}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              secondary="Dashboards"
              className={open ? "" : "opacity-0"}
              sx={{
                "& .MuiListItemText-secondary": {
                  fontWeight: "600",
                },
              }}
            />
            {showDashboard && (dashboardOpen ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
        </ListItem>
        {showDashboard && (
          <Collapse in={dashboardOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {dashboardSubItems.map((item) => (
                <ListItemButton
                  key={item.text}
                  selected={selectedItem === item.text}
                  onClick={() => setSelectedItem(item.text)}
                  className="pl-14 min-h-[40px]"
                >
                  <ListItemIcon className="min-w-0 mr-3">
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText secondary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        )}
        {/* Collapsible Admin Menu Items */}
        <ListItem disablePadding className="block">
          <ListItemButton
            onClick={handleTeamNextToggle}
            className={`min-h-[48px] justify-center ${
              open ? "px-2.5" : "px-2.5 py-3"
            }`}
          >
            <ListItemIcon className={`min-w-0 ${open ? "mr-3" : "mr-auto"}`}>
              {[2, 3, 4, 5, 6].includes(role_id) ? (
                <AdminLockOpenIcon />
              ) : (
                <AdminLockIcon />
              )}
            </ListItemIcon>
            <ListItemText
              secondary="Admin"
              className={open ? "" : "opacity-0"}
              sx={{
                "& .MuiListItemText-secondary": {
                  fontWeight: "600",
                },
              }}
            />
            {showTeam && (teamOpen ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
        </ListItem>
        {showTeam && (
          <Collapse in={teamOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {teamSubItems.map((item) => (
                <ListItemButton
                  key={item.text}
                  selected={selectedItem === item.text}
                  onClick={() => setSelectedItem(item.text)}
                  className="pl-14 min-h-[40px]"
                >
                  <ListItemIcon className="min-w-0 mr-3">
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText secondary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        )}
        {/* Learn Next Items */}
        <ListItem disablePadding className="block">
          <ListItemButton
            onClick={handleLearnNextToggle}
            className={`min-h-[48px] justify-center ${
              open ? "px-2.5" : "px-2.5 py-3"
            }`}
          >
            <ListItemIcon className={`min-w-0 ${open ? "mr-3" : "mr-auto"}`}>
              <LearnNextIcon />
            </ListItemIcon>
            <ListItemText
              secondary="Learn Next"
              className={open ? "" : "opacity-0"}
              sx={{
                "& .MuiListItemText-secondary": {
                  fontWeight: "600",
                },
              }}
            />
            {showLearnNext && (learnOpen ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
        </ListItem>
        {showLearnNext && (
          <Collapse in={learnOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {learNextItems.map((item) => (
                <ListItemButton
                  key={item.text}
                  selected={selectedItem === item.text}
                  onClick={() => setSelectedItem(item.text)}
                  className="pl-14 min-h-[40px]"
                >
                  <ListItemIcon className="min-w-0 mr-3">
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText secondary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        )}
        {/* Collapsible Profile Items*/}
        <ListItem disablePadding className="block">
          <ListItemButton
            onClick={handleProfileToggle}
            className={`min-h-[48px] justify-center ${
              open ? "px-2.5" : "px-2.5 py-3"
            }`}
          >
            <ListItemIcon className={`min-w-0 ${open ? "mr-3" : "mr-auto"}`}>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText
              secondary="Profile"
              className={open ? "" : "opacity-0"}
              sx={{
                "& .MuiListItemText-secondary": {
                  fontWeight: "600",
                },
              }}
            />
            {open && (profileOpen ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
        </ListItem>
        {showProfileOpen && (
          <Collapse in={profileOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {profileSubItems.map((item) => (
                <ListItemButton
                  key={item.text}
                  selected={selectedItem === item.text}
                  onClick={() => setSelectedItem(item.text)}
                  className="pl-14 min-h-[40px]"
                >
                  <ListItemIcon className="min-w-0 mr-3">
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText secondary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        )}
        {/* Main Menu Bottom Items */}
        <Divider className="my-2" />
        {menuBottomItems.map((item) => (
          <ListItem key={item.text} disablePadding className="block">
            <ListItemButton
              selected={selectedItem === item.text}
              onClick={() => setSelectedItem(item.text)}
              className={`min-h-[48px] justify-center ${
                open ? "px-2.5" : "px-2.5 py-3"
              }`}
            >
              <ListItemIcon className={`min-w-0 ${open ? "mr-3" : "mr-auto"}`}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                onClick={(e) => {
                  if (item.text === "Logout") {
                    localStorage.clear();
                    sessionStorage.clear();
                    deleteUser();
                    history.push("/user/login");
                  }
                }}
                secondary={item.text}
                className={open ? "" : "opacity-0"}
                sx={{
                  "& .MuiListItemText-secondary": {
                    fontWeight: "600",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box className="flex flex-col h-screen">
      <Tip />
      <CssBaseline />
      {/* Header */}
      <AppBarComponent
        showHamburger={true}
        handleDrawerToggle={handleDrawerToggle}
      />
      ;{/* Section */}
      <Box className="flex flex-grow pt-16" sx={{ backgroundColor: "#F6F6F6" }}>
        {/* Drawer Content Goes Here */}
        <Drawer
          variant="permanent"
          sx={{
            width: open ? drawerWidth : miniDrawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: open ? drawerWidth : miniDrawerWidth,
              boxSizing: "border-box",
              transition: (theme) =>
                theme.transitions.create("width", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
              overflowX: "hidden",
              top: `${headerHeight}px`,
              height: `calc(100% - ${headerHeight}px)`,
              backgroundColor: "var(--background)",
            },
          }}
          open={open}
        >
          {drawer}
        </Drawer>
        {/* Content Goes Here */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            marginTop: 0,
            width: {
              sm: `calc(100% - ${open ? drawerWidth : miniDrawerWidth}px)`,
            },
            transition: (theme) =>
              theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            backgroundColor: "#F6F6F6",
          }}
          // className="mt-0"
        >
          <Box sx={{ marginTop: "2px" }}>{displayComponent()}</Box>

          {/* <Typography paragraph>{selectedItem} content goes here.</Typography> */}
        </Box>
      </Box>
      {/* version update message */}
      <Dialog
        open={openUpdateModal}
        onClose={() => setOpenUpdateModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Alert onClose={() => setOpenUpdateModal(false)} severity="info">
          New Linear app version available! Refresh with CTRL+F5 (Windows) or
          CMD+SHFT+R (Mac).
        </Alert>
      </Dialog>
    </Box>
  );
};

export default AnalyticDash;
