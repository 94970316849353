import React, { useState, useEffect } from "react";
import { API } from "../../../api";
import LinearButton from "../../../components/Button";
import NewModal from "../../../components/NewModal";
import Table from "../../../components/Table";
import { CircularProgress } from "@material-ui/core";
import CreateNewGroup from "@mui/icons-material/Add";
import ViewMembers from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";

import { Alert, Tooltip } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useStoreState } from "easy-peasy";

const Group = () => {
  const userData = useStoreState((state) => state.user.data);
  const { role_id } = userData;
  const history = useHistory();

  const [groups, setGroups] = useState([]);
  const [groupID, setGroupID] = useState();
  const [tableHeader, setTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);

  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [groupAdminEmail, setGroupAdminEmail] = useState("");

  const [currentGrp, setCurrentGrp] = useState({});
  const [updateName, setUpdateName] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [updateDescription, setUpdateDescription] = useState("");

  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isButtonProgress, setIsButtonProgres] = useState(false);
  const [isEditable, setIsEditable] = useState(true);

  const [showCreateNewGroupModal, setShowCreateNewGroupModal] = useState(false);
  const [showUpdateGroupModal, setShowUpdateGroupModal] = useState(false);

  const handleViewMembers = (groupId) => {
    history.push("/group-members", { groupId, showBackOff: true });
  };

  const createNewGroup = async () => {
    const activateTimer = () => {
      setTimeout(() => {
        setMessage("");
      }, 4000);
    };

    setIsButtonProgres(true);
    if (!groupName || !groupDescription || !groupAdminEmail) {
      setSeverity("error");
      setMessage("Please Enter all Fields");
      setIsButtonProgres(false);
      activateTimer();
      return;
    }

    try {
      const { data, status } = await API.post("group/create-org-group", {
        name: groupName,
        description: groupDescription,
        group_admin_email: groupAdminEmail,
      });
      if (status === 200 || status === 201 || status === 202) {
        setSeverity("success");
        setMessage("Group Created Successfuly!");
        getGroups();
        activateTimer();
      } else {
        setSeverity("error");
        setMessage(data.error ? data.error.message : "Error Occured");
        activateTimer();
      }
    } catch (err) {
      setSeverity("error");
      setMessage("An error occured. Please try again later.");
      activateTimer();
    }
    setGroupName("");
    setGroupDescription("");
    setGroupAdminEmail("");
    setIsButtonProgres(false);
  };

  const updateGroup = async () => {
    const activateTimer = () => {
      setTimeout(() => {
        setMessage("");
      }, 4000);
    };

    setIsButtonProgres(true);
    if (!updateId || !updateName || !updateDescription) {
      setSeverity("error");
      setMessage("Please Enter all Fields");
      setIsButtonProgres(false);
      activateTimer();
      return;
    }

    try {
      const { status } = await API.patch(`group`, {
        id: updateId,
        name: updateName,
        description: updateDescription,
      });
      if (status === 500 || status === 404 || status === 403) {
        setSeverity("error");
        setMessage("Failed to create Group,Error Occured");
        activateTimer();
      } else {
        setSeverity("success");
        setMessage("Details Updated Successfuly!");
        getGroups();
        activateTimer();
      }
    } catch (error) {
      setSeverity("error");
      setMessage("An error occured. Please try again later.");
      activateTimer();
    }
    setIsButtonProgres(false);
    getGroups();
  };

  const getGroups = async (role_id, admin_id, email) => {
    setIsLoading(true);
    if (role_id === 2) {
      setIsEditable(false);
      setGroupAdminEmail(email);
      const { data } = await API.get("group/org-groups", admin_id);
      setGroups(data);
    } else {
      const { data } = await API.get("group", "get-org-groups");
      setGroups(data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const admin_id = parseInt(localStorage.getItem("id"), 10);
    const email = localStorage.getItem("email");
    getGroups(role_id, admin_id, email);
  }, []);

  const getEdit = (grp) => {
    const name = grp?.name;
    const id = grp?.id;
    const description = grp?.description;
    return (
      <div>
        <button
          onClick={(e) => {
            setMessage("");
            setShowUpdateGroupModal(true);
            setCurrentGrp({ name, id, description });
            setUpdateName(name);
            setUpdateId(id);
            setUpdateDescription(description);
          }}
        >
          <Tooltip title="Update Group Details" arrow placement="top">
            <svg
              className="w-6 h-6"
              style={{ color: "#2288EE", fill: "#2288EE" }}
              viewBox="0 0 20 20"
            >
              <EditIcon />
            </svg>
          </Tooltip>
        </button>
      </div>
    );
  };

  const getViewMembers = (grp) => {
    return (
      <div>
        <button
          onClick={() => handleViewMembers(grp?.id)}
          className="flex space-x-1 bg-transparent items-center p-2 cursor-pointer"
        >
          <Tooltip title="View Group Members" arrow placement="top">
            <svg
              className="w-6 h-6 -mt-2"
              style={{ color: "#2288EE", fill: "#2288EE" }}
              viewBox="0 0 20 20"
            >
              <ViewMembers />
            </svg>
          </Tooltip>
        </button>
      </div>
    );
  };

  useEffect(() => {
    const tableData = [];
    groups &&
      groups?.map((group, index) => {
        const grpName = group.name.toUpperCase();
        const description = group.description;
        const edit = getEdit(group);
        const viewMembers = getViewMembers(group);
        const element = [
          grpName,
          description,
          <div className="flex flex-row gap-2">
            {edit}
            {viewMembers}
          </div>,
        ];
        tableData.push(element);
      });
    setTableHeader(["Group Name", "Description", "Actions"]);
    setTableBody(tableData);
  }, [groups]);

  return (
    <div>
      <div className="p-4 my-4 bg-white rounded-lg overflow-auto mx-2">
        {isLoading ? (
          <div className="flex justify-center items-center h-screen">
            <div style={{ marginTop: "-20vh" }}>
              <CircularProgress size={80} />
            </div>
          </div>
        ) : (
          <div>
            <div className="flex space-x-4">
              <div
                onClick={() => setShowCreateNewGroupModal(true)}
                className="flex space-x-1 bg-transparent items-center p-2 cursor-pointer bg-white text-blue-500 hover:bg-blue-50 transition-all duration-200 rounded"
              >
                <svg
                  className="w-6 h-6"
                  style={{ color: "#2288EE", fill: "#2288EE" }}
                  viewBox="0 0 20 20"
                >
                  <CreateNewGroup />
                </svg>
                <p className="text-gray-800">Create New Group</p>
              </div>
            </div>
            <div className="mt-4">
              <Table header={tableHeader} body={tableBody} />
            </div>
          </div>
        )}

        {showCreateNewGroupModal && (
          <NewModal
            heading={<div className="block text-center">New Group Details</div>}
            body={
              <div className="mx-16">
                <div className="w-full">
                  <label className="block mb-1 text-sm font-medium text-gray-600">
                    Group Name
                  </label>
                  <input
                    className="w-full px-3 py-2 rounded-md border border-gray-300 outline-none focus:ring focus:ring-blue-400 transition-all duration-300"
                    type="text"
                    required
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                </div>
                <div className="w-full mt-2">
                  <label className="block mb-1 text-sm font-medium text-gray-600">
                    Group Description
                  </label>
                  <input
                    className="w-full px-3 py-2 rounded-md border border-gray-300 outline-none focus:ring focus:ring-blue-400 transition-all duration-300"
                    type="text"
                    required
                    value={groupDescription}
                    onChange={(e) => setGroupDescription(e.target.value)}
                  />
                </div>

                <div className="w-full mt-2">
                  <label className="block mb-1 text-sm font-medium text-gray-600">
                    Group Admin Email
                  </label>
                  <input
                    className="w-full px-3 py-2 rounded-md border border-gray-300 outline-none focus:ring focus:ring-blue-400 transition-all duration-300"
                    type="email"
                    required
                    disabled={!isEditable}
                    value={groupAdminEmail}
                    onChange={(e) => setGroupAdminEmail(e.target.value)}
                  />
                </div>

                <div className="w-full mt-4">
                  <LinearButton
                    onClick={createNewGroup}
                    text={
                      isButtonProgress ? (
                        <CircularProgress size={30} />
                      ) : (
                        "Submit"
                      )
                    }
                  />
                </div>

                <div className="mt-6 flex justify-center inline-block ">
                  {message && message !== "" && (
                    <Alert severity={severity}>{message}</Alert>
                  )}
                </div>
              </div>
            }
            onClose={() => {
              setShowCreateNewGroupModal(false);
            }}
          />
        )}

        {showUpdateGroupModal && (
          <NewModal
            heading={
              <div className="block text-center">Edit Group Details</div>
            }
            body={
              <div className="mx-16">
                <div className="w-full">
                  <label className="block mb-1 text-sm font-medium text-gray-600">
                    Group Name
                  </label>
                  <input
                    className="w-full px-3 py-2 rounded-md border border-gray-300 outline-none focus:ring focus:ring-blue-400 transition-all duration-300"
                    type="text"
                    required
                    defaultValue={currentGrp.name}
                    onChange={(e) => setUpdateName(e.target.value)}
                  />
                </div>
                <div className="w-full mt-2">
                  <label className="block mb-1 text-sm font-medium text-gray-600">
                    Group Description
                  </label>
                  <input
                    className="w-full px-3 py-2 rounded-md border border-gray-300 outline-none focus:ring focus:ring-blue-400 transition-all duration-300"
                    type="text"
                    required
                    defaultValue={currentGrp.description}
                    onChange={(e) => setUpdateDescription(e.target.value)}
                  />
                </div>

                <div className="w-full mt-4">
                  <LinearButton
                    onClick={updateGroup}
                    text={
                      isButtonProgress ? (
                        <CircularProgress size={30} />
                      ) : (
                        "Update"
                      )
                    }
                  />
                </div>

                <div className="mt-6 flex justify-center inline-block ">
                  {message && message !== "" && (
                    <Alert severity={severity}>{message}</Alert>
                  )}
                </div>
              </div>
            }
            onClose={() => {
              setShowUpdateGroupModal(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Group;
