import React, { useEffect, useState } from "react";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./index.css";
import LinearButton from "../../../components/Button";
import { API } from "../../../api";
import { useHistory } from "react-router-dom";
import { CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import NewModal from "../../../components/NewModal";

const countries = ["in", "us", "gb", "ca", "au", "sg", "de", "hk"];
const COUNTRY = [
  { value: "", text: "" },
  { value: "india", text: "India" },
  { value: "usa", text: "USA" },
  { value: "uk", text: "UK" },
  { value: "canada", text: "Canada" },
  { value: "australia", text: "Australia" },
  { value: "singapore", text: "Singapore" },
  { value: "germany", text: "Germany" },
  { value: "hong_kong", text: "Hong Kong" },
];

const OrgDetails = () => {
  const history = useHistory();
  const [basicData, setBasicData] = useState({
    name: "",
    address: "",
    country: "",
    phone: "",
    gst_number: "",
  });

  const [severity, setSeverity] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [heading, setHeading] = useState("");
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const _handleBasicChange = (e, value) => {
    if (value) {
      setBasicData({ ...basicData, phone: value });
    } else {
      setBasicData({ ...basicData, [e.target.name]: e.target.value });
    }
  };

  const _updateBasic = async () => {
    const { status } = await API.post(
      "organization/update-organization-details",
      basicData
    );
    return status;
  };

  const _handleSubmit = async () => {
    setIsLoading(true);
    const basicStatus = await _updateBasic();
    getOrganizationDetails();
    setOpenSnackBar(true);
    if (basicStatus === 200) {
      setSeverity("success");
      setMessage("Data Saved Successfully!");
    } else {
      setSeverity("error");
      setMessage("Internal server Error, Try Again Later!");
    }
  };

  async function getOrganizationDetails() {
    setIsLoading(true);
    const orgDetails = await API.get("organization", "organization-details");
    if (orgDetails.status !== 200) history.push("/user/login");
    setBasicData(orgDetails.data);
    setIsLoading(false);
  }

  useEffect(() => {
    getOrganizationDetails();
  }, []);

  return (
    <div>
      <div className="p-4 my-4 bg-white rounded-lg overflow-auto mx-2">
        {isLoading ? (
          <div className="flex justify-center items-center h-screen">
            <div style={{ marginTop: "-20vh" }}>
              <CircularProgress size={80} />
            </div>
          </div>
        ) : (
          <div>
            <div
              className="flex items-center px-4"
              style={{ backgroundColor: "#E9F3FD", height: "2.625rem" }}
            >
              <h6>Organization Information</h6>
            </div>
            <div>
              <div className="flex flex-col sm:flex-row p-4 gap-4">
                <div className="flex flex-col w-full sm:w-2/5">
                  <label className="body-small-1">Email address</label>
                  <input
                    className="input-box body-small-1 "
                    placeholder="example@example.com"
                    value={basicData?.email}
                    disabled={true}
                  />
                </div>
                <div className="flex flex-col w-full sm:w-2/5">
                  <label className="body-small-1">Organization Name</label>
                  <input
                    className="input-box body-small-1"
                    name="name"
                    placeholder="Enter organization name"
                    value={basicData.name}
                    onChange={_handleBasicChange}
                  />
                </div>
              </div>

              <div className="flex flex-col sm:flex-row p-4 gap-4">
                <div className="flex flex-col w-full sm:w-2/5">
                  <label className="body-small-1">Country</label>
                  <select
                    onChange={_handleBasicChange}
                    name="country"
                    className="input-box body-small-1"
                  >
                    {COUNTRY.map((option, index) => {
                      return (
                        <option
                          value={option.value}
                          key={index}
                          selected={
                            option.value === basicData.country ? true : false
                          }
                        >
                          {option.text}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="flex flex-col w-full sm:w-2/5">
                  <label className="body-small-1">Address</label>
                  <input
                    className="input-box body-small-1"
                    name="address"
                    placeholder="123 Main Street, City, State, Country"
                    value={basicData.address}
                    onChange={_handleBasicChange}
                  />
                </div>
              </div>

              <div className="flex flex-col sm:flex-row p-4 gap-4">
                <div className="flex flex-col w-full sm:w-2/5">
                  <label className="body-small-1">Phone Number</label>
                  <div className="w-full">
                    <ReactPhoneInput
                      country={"in"}
                      name="phone"
                      placeholder="123-456-7890"
                      value={basicData.phone}
                      onChange={(value, country, e) =>
                        _handleBasicChange(e, value)
                      }
                      onlyCountries={countries}
                      inputStyle={{ width: "100%", height: "38px" }}
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full sm:w-2/5">
                  <label className="body-small-1">GST Number</label>
                  <input
                    className="input-box body-small-1"
                    name="gst_number"
                    placeholder="GST123456789"
                    value={basicData.gst_number}
                    onChange={_handleBasicChange}
                  />
                </div>
              </div>

              <div className=" flex flex-col gap-4 items-center md:items-start md:mx-4 mt-4">
                <LinearButton
                  text="Save"
                  style={{ background: "#003366", borderRadius: "10px" }}
                  onClick={_handleSubmit}
                />
              </div>
            </div>
          </div>
        )}
        <Snackbar
          open={openSnackBar}
          autoHideDuration={4000}
          onClose={() => setOpenSnackBar(false)}
          TransitionComponent={"SlideTransition"}
        >
          <Alert
            severity={severity}
            sx={{
              width: "100%",
              // color: "white",
              // "& .MuiAlert-icon": {
              //   color: "white",
              // },
            }}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default OrgDetails;
