import React, { useEffect, useState } from "react";
import { API } from "../../../api";
import { useHistory } from "react-router-dom";
import "./index.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";
import DailyProgressChart from "../../../components/DailyProgressChart";
import UpcomingCalendar from "../../../components/UpcomingCalender";
import TempPadding from "../../../components/DashboardComponents/TempPadding";
import Leaderboard from "../../../components/DashboardComponents/Leaderboard";
import ChallengeList from "../../../components/DashboardComponents/ChallengeList";
import ActivityList from "../../../components/DashboardComponents/ActivityList";
import UpcomingTasks from "../../../components/DashboardComponents/UpcomingTasks";
import DailyProgress from "../../../components/DashboardComponents/DailyProgress";
import Tip from "../../../components/DashboardComponents/Tip";
import NewModal from "../../../components/NewModal";
import LinearButton from "../../../components/Button";
import Feedback from "../../Feedback";
import UserDashboardMobile from "./Mobile";
import {
  MONTH_LIST,
  WEEKDAY_LIST,
  WEEKDAY_SHORTLIST,
} from "../../../lib/constants";
import { addDays } from "../../../lib/helpers";
import useScreenSize from "../../../hooks/useScreenSize";
import DayNavigator from "../../../components/DashboardComponents/DayNavigator";
import GoalToggle from "../../../components/DashboardComponents/ProgramToggle";
import Toast from "../../../components/Toast";
import learn_anywhere from "../../../assests/learn_anywhere.svg";
import ContextCardList from "../../../components/DashboardComponents/ContextCardList";
import { Alert, Snackbar } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import SubscriptionModal from "../../../components/DashboardComponents/SubscriptionModal/SubscriptionModal";
import ProgramEnrolledTable from "../../../components/DashboardComponents/ProgramEnrolledTable/ProgramEnrolledTable";

const UserDashboard = () => {
  const [date, setDate] = useState(new Date());
  const [dayAdjust, setDayAdjust] = useState(0);
  const [weekBounds, setWeekBounds] = useState({ firstday: "", lastday: "" });
  const [askFeedbackModal, showAskFeedbackModal] = useState(false);
  const [selectedProgramID, setSelectedProgramID] = useState("all");
  const { isMobile } = useScreenSize();
  const [showWelcomeBackModal, toggleWelcomeBackModal] = useState(false);
  const [waitForUnpause, setWaitForUnpause] = useState(true);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  //Receiving message from broadcast channel
  const broadcastChannel = new BroadcastChannel("new-content-channel");
  broadcastChannel.onmessage = (event) => {
    if (event.data && event.data.type === "newContentAvailable") {
      setOpenUpdateModal(true);
    }
  };

  // const history = useHistory();

  // useEffect(() => {
  //   const expiry = localStorage.getItem("expiry");
  //   const now = new Date();
  //   const token = localStorage.getItem("token");
  //   if (!token || !expiry || new Date(expiry).getTime() <= now.getTime()) {
  //     history.push("/user/login");
  //   }
  // }, []);

  // useEffect(() => {
  //   async function getLastActivityDate() {
  //     const { data, status } = await API.get("user-data", "todo-date");
  //     if (data.active_todo_date) {
  //       const date = new Date(data.active_todo_date);
  //       const today = new Date();
  //       const diff = (date.getTime() - today.getTime()) / (1000 * 60 * 60 * 24);
  //       if (Math.trunc(diff) === 0) setDayAdjust(0);
  //       else if (diff > 0) setDayAdjust(Math.ceil(diff));
  //       else setDayAdjust(Math.ceil(diff));
  //       setDate(date);
  //     }
  //     if (status === 401) {
  //       history.push("/user/login");
  //     }
  //   }
  //   getLastActivityDate();
  // }, []);

  useEffect(() => {
    const resumeAnyUnpausedGoals = async () => {
      try {
        const { data: enrolledGoals, status } = await API.get(
          "goal",
          "enrolled-goals"
        );
        if (status === 200) {
          const pausedGoalIndex = enrolledGoals.findIndex((goal) => {
            return (
              goal.paused_due_to_inactivity && goal.paused && !goal.quitted
            );
          });
          console.log(pausedGoalIndex);
          if (pausedGoalIndex === -1) {
            setWaitForUnpause(false);
          }
          enrolledGoals.forEach(async (goal) => {
            if (goal.paused_due_to_inactivity) {
              const { status: resume_status } = await API.post(
                `goal/resume/${goal.goal_id}`,
                {}
              );
              if (resume_status === 200) {
                console.log("Goal has been resumed successfully");
                toggleWelcomeBackModal(true);
                setWaitForUnpause(false);
              }
            }
          });
        }
      } catch (err) {
        console.log(err);
        toggleWelcomeBackModal(false);
        setWaitForUnpause(false);
      }
    };
    resumeAnyUnpausedGoals();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { data, status } = await API.get("feedback", "user-feedback");
      if (status === 200 && data.length === 0) {
        const { data, status } = await API.get("user-data", "stats");
        if (
          status === 200 &&
          data.no_of_days_of_learning !== 0 &&
          data.no_of_days_of_learning % 10 === 0
        ) {
          showAskFeedbackModal(true);
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    var curr = new Date(); // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    var firstday = new Date(curr.setDate(first));
    var lastday = new Date(curr.setDate(last));

    setWeekBounds({ ...weekBounds, firstday, lastday });
  }, []);

  if (isMobile) {
    return (
      <div>
        <UserDashboardMobile />
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: "#F6F6F6" }} className="min-h-screen">
      {showWelcomeBackModal && (
        <NewModal
          heading="Welcome Back"
          body={
            <div className="text-center welcomeModalBody">
              {/* <h5>Welcome Back</h5> */}
              <p>
                Remember that developing routines requires a little bit of
                learning and practice every day.
              </p>
              <img
                alt="learn_anywhere"
                src={learn_anywhere}
                style={{ height: "250px" }}
              />
            </div>
          }
          footer={
            <div className="program-modal-footer">
              <LinearButton
                text={"Continue"}
                onClick={() => toggleWelcomeBackModal(false)}
              />
            </div>
          }
          hasCloseButton={false}
        />
      )}
      {/* <Tip /> */}
      <div className="dashboardContainer">
        {/* Upcoming Task column */}
        <div className="dashboardColumns cornerDashBoardColumn">
          {/* <div className="m-4">
            <div>
              <h6>
                {WEEKDAY_LIST[date.getDay()]}, {date.getDate()}{" "}
                {MONTH_LIST[date.getMonth()]} {date.getFullYear()}
              </h6>
              <div className="flex flex-row">
                {WEEKDAY_SHORTLIST.map((day, index) => {
                  return (
                    <div
                      className="flex flex-col flex-grow p-4 justify-between justify-center items-center"
                      key={index}
                    >
                      <div
                        onClick={() => {
                          const newDate = addDays(weekBounds.firstday, index);
                          setDate(newDate);
                          setDayAdjust(newDate.getDay() - new Date().getDay());
                        }}
                        style={
                          date.getDate() ===
                            addDays(weekBounds.firstday, index).getDate()
                            ? {
                              backgroundColor: "#E9F3FD",
                              padding: "10px",
                              marginTop: "-10px",
                              borderRadius: "10px",
                            }
                            : null
                        }
                      >
                        <p className="body-extra-small-1">{day}</p>
                        <p className="body-small-1">
                          {addDays(weekBounds.firstday, index).getDate()}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <UpcomingTasks />
            </div>
          </div> */}
          {/* To Do Column */}
          {/* <TempPadding /> */}
        </div>
        <div className="dashboardColumns centerDashBoardColumn">
          {/* Activity Column */}
          <div className="lg:col-span-2 m-4">
            <div className="flex flex-col justify-center items-center gap-4">
              <GoalToggle setSelectedProgramID={setSelectedProgramID} />

              <DayNavigator
                setDayAdjust={setDayAdjust}
                setDate={setDate}
                dayAdjust={dayAdjust}
                date={date}
              />
            </div>
            <div className="flex flex-row justify-center mx-auto my-4">
              <h5 className="w-32 text-center task-heading task-heading-container ">
                Learn
              </h5>
            </div>
            <ContextCardList dayAdjust={dayAdjust} />
            {process.env.REACT_APP_ENV === "development" && (
              <SubscriptionModal />
            )}

            <ActivityList
              waitForUnpause={waitForUnpause}
              dayAdjust={dayAdjust}
              selectedProgramID={selectedProgramID}
            />
          </div>
          {/*Challenges Column*/}

          <div className="lg:col-span-2 m-4">
            <div className="flex flex-row justify-center">
              <div className="flex justify-center task-heading-container">
                <h5 className="text-center w-32 task-heading">Do</h5>
              </div>
            </div>
            <ChallengeList
              waitForUnpause={waitForUnpause}
              dayAdjust={dayAdjust}
              selectedProgramID={selectedProgramID}
            />
          </div>
        </div>

        <div className="dashboardColumns chartsColumn">
          <div className="">
            <div className="column-title m-2 px-2 mx-auto ">
              <DailyProgress />
            </div>
          </div>
          {/* {Program Enrolled Column} */}
          <div className="">
            <ProgramEnrolledTable />
          </div>
          {/*Leader Board Column*/}
          <div className="">
            <Leaderboard />
          </div>
        </div>
        <div className="dashboardColumns cornerDashBoardColumn"></div>
      </div>
      {askFeedbackModal && (
        <div className="hidden md:block">
          <NewModal
            ModalStyle={{
              // marginLeft: "-20vh",
              width: "130vh",
            }}
            body={
              <div className="mx-16 text-center">
                <h5 className="mb-8">
                  Are you enjoying Linear?
                  <br /> Share your feedback.
                </h5>
                <Feedback showAskFeedbackModal={showAskFeedbackModal} />
              </div>
            }
            onClose={() => {
              showAskFeedbackModal(false);
            }}
          />
        </div>
      )}
      {/* version update message */}
      {/* <Dialog
        open={openUpdateModal}
        onClose={() => setOpenUpdateModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Alert onClose={() => setOpenUpdateModal(false)} severity="info">
          New Linear app version available! Refresh with CTRL+F5 (Windows) or
          CMD+SHFT+R (Mac).
        </Alert>
      </Dialog> */}
    </div>
  );
};

export default UserDashboard;
