import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, Box, TextField } from "@mui/material";
import { useStoreState } from "easy-peasy";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import NotificationNoneIcon from "@mui/icons-material/NotificationsNone";
import linear_logo from "../../../assests/images/linear_logo.png";
import linear_text from "../../../assests/images/linear_text.png";
import default_profile_pic from "../../../assests/images/default_profile_pic.png";
import { Link } from "react-router-dom";
import SearchEngine from "../../../components/SearchEngine/SearchEngine";

const AppBarComponent = ({ showHamburger, handleDrawerToggle }) => {
  const userData = useStoreState((state) => state.user.data);
  const userNewPhotoURL = userData?.newPhotoURL;
  const [showSearchEngine, setShowSearchEngine] = useState(false);

  return (
    <div>
      <AppBar
        position="fixed"
        className="bg-white text-black z-50"
        sx={{ backgroundColor: "#003366" }}
      >
        <Toolbar className="flex justify-between items-center">
          {/* Left section: Hamburger and Logo */}
          <div className="flex items-center">
            {showHamburger && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className="mr-2"
              >
                <MenuIcon />
              </IconButton>
            )}
            <Link to="/home">
              <Box className="flex items-center ml-2">
                <img
                  alt="Linear Logo"
                  src={linear_logo}
                  className="h-12 mr-2"
                />
                <img alt="Linear Text" src={linear_text} className="h-16" />
              </Box>
            </Link>
          </div>

          {/* Right: Search, Notification, and Avatar */}
          <div className="flex justify-between items-center">
            <TextField
              sx={{
                maxWidth: "180px",
                margin: "0 16px",
                maxHeight: "40px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "16px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--prussian-blue-50)",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--prussian-blue-50) !important",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--prussian-blue-50)",
                  },
                },
                input: { color: "var(--white)" },
              }}
              onClick={() => setShowSearchEngine(true)}
              placeholder="Search...."
              variant="outlined"
              size="small"
              className="hidden md:block cursor-pointer"
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: "var(--white)" }} />,
                readOnly: true,
              }}
            />
            <NotificationNoneIcon className="h-4 mr-2" />
            <img
              alt="profile_pic"
              style={{
                width: 40,
                height: 40,
                minWidth: 40,
                minHeight: 40,
                marginRight: 10,
                borderRadius: "50%",
              }}
              src={userNewPhotoURL || default_profile_pic}
            />
          </div>
        </Toolbar>
      </AppBar>
      <div>
        <SearchEngine open={showSearchEngine} setOpen={setShowSearchEngine} />
      </div>
    </div>
  );
};

export default AppBarComponent;
