import React, { useEffect, useState } from "react";
import { API } from "../../api";
import "./index.css";
// import ProgramContainer from "../../containers/Programs/ProgramContainer";
import { CircularProgress } from "@material-ui/core";
import ProgramCard from "../../containers/ProgramCard/ProgramCard";

const Program = ({ enrolledGoals }) => {
  const [programList, setProgramList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const enrolledGoalIds =
    enrolledGoals?.map((userProgram) => userProgram?.id) || [];

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const org_id = localStorage.getItem("org_id");
        if (org_id !== "null" && org_id !== null) {
          const { data, status } = await API.get("org-program-access", org_id);
          if (status === 200) {
            setProgramList(data);
          }
        } else {
          const { data, status } = await API.get("program", "all-by-user-role");
          if (status === 200) {
            setProgramList(data);
          }
        }
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className="p-4 my-4 bg-white rounded-lg flex justify-center items-center h-screen">
        <div style={{ marginTop: "-20vh" }}>
          <CircularProgress size={80} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="p-4 my-4 bg-white rounded-lg overflow-auto mx-2">
        <h4 className="text-center my-4 block sm:hidden">Select Program</h4>
        {programList.programs && programList.programs.length > 0 ? (
          <div className="flex flex-row mt-4 flex-wrap justify-center gap-16 sm:gap-32">
            {/* {programList.programs.map((program) => (
            <ProgramContainer
              key={program.id}
              id={program.id}
              name={program.name}
              description={program.description}
              imageUrl={program.display_image_url}
              type={program.program_type}
              isEnrolled={enrolledGoalIds.includes(program.id)}
            />
          ))} */}
            {programList.programs.map((program) => (
              <ProgramCard
                key={program.id}
                id={program.id}
                name={program.name}
                description={program.description}
                imageUrl={program.display_image_url}
                type={program.program_type}
                isEnrolled={enrolledGoalIds.includes(program.id)}
              />
            ))}
          </div>
        ) : (
          <div class="flex justify-center items-start bg-white ">
            <div class="text-center p-8 bg-white-200 rounded-t-lg shadow-md w-full md:w-1/2">
              <p class="text-3xl font-bold text-gray-500 mb-4">
                No Programs Available For You🦉!
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Program;
