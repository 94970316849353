import React from "react";
import { Switch, Route } from "react-router-dom";
import ForgotPassword from "./Forgot Password";
import ConfirmEmail from "./ConfirmEmail";
import UserDashboard from "./UserDashboard";
import UserLogin from "./Login";
import UserSignUp from "./SignUp";
import ResetPassword from "./ResetPassword";
import ErrorPage from "../404RedirectPage";
import PostLogin from "./PostLogin";
import PrivateRoute from "../../routers/PrivateRoute";
import ProgramSchedule from "../Program/ProgramSchedule/ProgramSchedule";
// import Report from "../Admin/Reports";

const UserRoutes = () => {
  return (
    <Switch>
      <Route exact path="/user/signup" component={UserSignUp} />
      <Route exact path="/user/confirm" component={ConfirmEmail} />
      <Route exact path="/user/login" component={UserLogin} />
      <Route exact path="/user/post-login" component={PostLogin} />
      <Route exact path="/user/forgot-password" component={ForgotPassword} />
      <Route exact path="/user/reset-password" component={ResetPassword} />
      <PrivateRoute exact path="/home" component={UserDashboard} />
      <PrivateRoute
        exact
        path="/user/program-schedule"
        component={ProgramSchedule}
      />
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default UserRoutes;
